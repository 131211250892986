.search-results-section {
  display: block;
}

.search-results-section + .search-results-section {
  margin-top: 3rem;
}

.search-result-item {
  display: flex;
  align-items: center;
  justify-content: start;
}

.search-result-item + .search-result-item {
  margin-top: 2.5rem;
}

@media (width < 500px) {
  .search-result-item {
    flex-direction: column;
  }
}

.search-image {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  width: 140px;
}

@media (width >= 500px) {
  .search-image {
    margin-right: 30px;
  }
}

@media (width < 500px) {
  .search-image {
    align-items: flex-start;
    margin-bottom: 30px;
  }
}

.search-form-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.search-form-input-label {
  flex: 1 0 auto;
  margin-right: 25px;
}

@media (width < 500px) {
  .search-form-input-container {
    flex-direction: column;
  }

  .search-form-input-label {
    margin-right: 0;
  }

  .search-form-button {
    margin-top: 10px;
  }
}

@media (width > 500px) {
  .js-search-input {
    padding-left: 40px;
    background: url(../images/search.svg) 7px center / 23px 23px no-repeat;
  }
}

.search-form-button[aria-disabled='true'] {
  opacity: 0.5;
}

.search-form-button:focus,
.search-form-input:focus {
  box-shadow: 0 0 0 4px rgb(138 138 138 / 38%);
}

.search-results-text {
  font-weight: bold;
}

.search-results-text.mod-show {
  display: block;
  margin: 20px 0;
}

.search-image-number {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0.5rem);

  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
  font-size: 0.85rem;
  font-weight: normal;
  padding: 0.25rem;

  /* Pass clicks through */
  user-select: none;
  pointer-events: none;
}

.search-link {
  font-size: 1.125rem;
  font-weight: bold;
  text-decoration: none;
}

.search-link:--focus-hover {
  text-decoration: underline;
}

.search-link:focus {
  outline: 2px solid #000;
}

.search-text {
  margin: 0.5rem 0 0;
  line-height: 1.5;
  padding-left: 1rem;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

.search-link-issue-name {
  display: block;
  font-size: 14px;
  font-weight: normal;
  margin: 0.25rem 0;
  opacity: 0.7;
}
