:root {
  --heading-font-size: 25px;
  --strapline-font-size: 20px;
  --bodyheading-font-size: 20px;
  --body-font-size: 18px;
  --buttonheading-font-size: 18px;
  --buttonbody-font-size: 18px;
  --element-background: #fff;
  --font-colour: #000;
}

.page-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 35px;
  font-variant-ligatures: normal;
}

.panel-static {
  position: relative;
  max-width: 550px;
  overflow-wrap: inherit;
  list-style: none;
  margin: 0;
  padding: 0;
}

.link-panel-static {
  font-size: var(--strapline-font-size);
  font-weight: bold;
  color: #00f;
}

.poll-title {
  padding: 0;
  font-size: var(--heading-font-size);
  font-weight: bold;
  color: #000;
}

.poll-label {
  margin-left: 10px;
  font-size: var(--body-font-size);
  color: #000;
}

.poll-submit {
  width: 100%;
  margin-top: 15px;
}

.video-element-static {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-panel-static {
  position: relative;

  /* stylelint-disable-next-line */
  opacity: 1 !important;
  padding: 0;
  border: 0;
  cursor: pointer;
}

.chart-element-static {
  position: relative;
  width: 100%;
  height: 390px;
}

.story-panel-static {
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow-y: auto;
  text-align: left;
}

.story-panel-headline-static {
  font-size: var(--heading-font-size);
  font-weight: bold;
}

.story-panel-strapline-static {
  font-size: var(--strapline-font-size);
  font-weight: bold;
}

.story-panel-headline-static strong,
.story-panel-strapline-static strong {
  font-weight: bolder;
}

.story-panel-body-static {
  font-size: var(--body-font-size);
}

.interactivity-item .story-panel-headline-static {
  font-size: var(--buttonheading-font-size);
  font-weight: bold;
}

.interactivity-item .story-panel-strapline-static {
  font-size: var(--buttonheading-font-size);
}

.interactivity-item .story-panel-body-static {
  font-size: var(--buttonbody-font-size);
}

.list-panel-item-headline-static {
  font-size: var(--heading-font-size);
  font-weight: bold;
}

.list-panel-item-headline-static strong {
  font-weight: bolder;
}

.list-panel-item-body-static {
  font-size: var(--body-font-size);
}

.interactivity-item .list-panel-item-headline-static {
  font-size: var(--buttonheading-font-size);
  font-weight: bold;
}

.interactivity-item .list-panel-item-body-static {
  font-size: var(--buttonbody-font-size);
}

.poll-panel {
  padding: 0;
  margin: 0;
}

.panel,
.video-element,
.chart-element {
  position: absolute;
  transform-origin: top left;
}

.stack-panel {
  border-style: solid;
}

.video-html {
  width: 100%;
  height: 100%;
}

.image-panel {
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
  padding: 0;
}

.image-panel-img {
  display: block;
  height: 100%;
}

.image-panel-img.mod-full-width {
  width: 100%;
}

.story-panel {
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
  word-break: break-word;
}

.story-panel[type='button'] {
  cursor: pointer;
}

.story-panel-headline,
.story-panel-strapline,
.story-panel-body {
  width: 100%;
}

/* stylelint-disable */
.story-panel-body h3:nth-child(1) {
  padding-top: 0;
}

.story-panel-headline *,
.story-panel-strapline *,
.story-panel-body * {
  margin: 0;
}
/* stylelint-enable */

.story-panel-headline,
.story-panel-strapline,
.story-panel-body:not(.mod-text-rotated-panel) {
  flex-shrink: 0;
}

.block-panel {
  position: absolute;
}

.svg-panel svg {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.list-panel {
  display: flex;
  position: absolute;
  margin: 0;
  width: 100%;
  height: 100%;
  list-style: none;
}

.list-panel-headline {
  width: 100%;
  height: 100%;
}

.list-panel-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-style: solid;
  word-break: break-word;
}

div.list-panel-item {
  pointer-events: none;
}

button.list-panel-item {
  cursor: pointer;
}

.list-panel-item-headline,
.list-panel-item-body {
  width: 100%;
  line-height: 1;
  pointer-events: none;
}

.list-panel-item-headline strong,
.list-panel-item-body strong {
  font-weight: revert;
}

.story-panel-headline strong,
.story-panel-strapline strong {
  font-weight: revert;
}

.list-panel * {
  margin: 0;
}

.page-warning {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 5px;
  left: 50%;
  z-index: 2000;
  max-width: 240px;
  padding: 5px 10px;
  border: 2px solid #fff;
  border-radius: 4px;
  text-align: left;
  background: #808080ad;
  transform: translateX(-50%);
  gap: 10px;
}

.page-warning-logo {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background: url('../images/warning.svg') center / contain no-repeat;
}

.page-warning-text {
  font-size: 10px;
  line-height: 1.3;
  color: #fff;
}
