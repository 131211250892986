/* stylelint-disable selector-max-attribute, declaration-property-unit-blacklist */
:root {
  --popupPosition: calc(100% + 20px);
  --arrowPosition: -7px;
}

.toolbar-menu {
  display: flex;
  position: relative;
  height: 28px;
}

.toolbar-popup {
  display: none;
  position: absolute;
  min-width: 170px;
  max-width: 240px;
  box-sizing: border-box;
  padding: 10px 0;
  border-radius: 4px;
  box-shadow: 0 0 6px hsl(0 0% 0% / 49%);
  color: #fff;
  background: #222;
  cursor: default;
  pointer-events: none;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion) {
  .toolbar-popup {
    transition: none;
  }
}

.toolbar-popup.mod-account {
  right: 0;
  min-width: 130px;
}

[data-position='top'] .toolbar-popup {
  top: var(--popupPosition);
}

[data-position='bottom'] .toolbar-popup {
  bottom: var(--popupPosition);
}

.toolbar-popup[aria-hidden='false'] {
  display: block;
  pointer-events: all;
  transform: translateY(0);
  z-index: 10;
}

.toolbar-popup.mod-account.mod-menu {
  top: calc(100% + 25px);
}

[data-position='top'] .toolbar-popup[aria-hidden='true'] {
  transform: translateY(15px);
}

[data-position='bottom'] .toolbar-popup[aria-hidden='true'] {
  transform: translateY(-15px);
}

.toolbar-popup-arrow {
  display: block;
  position: absolute;
  left: 12px;
  border: 0.5rem solid;
  margin-right: 0;
  margin-left: 0;
  transform: rotate(45deg);
}

.toolbar-popup-arrow.mod-account {
  left: calc(100% - 24px);
}

.toolbar-popup-arrow.mod-account.mod-menu {
  top: -9px;
}

[data-position='top'] .toolbar-popup-arrow {
  top: var(--arrowPosition);
  box-shadow: -3px -3px 3px hsl(0 0% 0% / 19%);
}

[data-position='bottom'] .toolbar-popup-arrow {
  bottom: var(--arrowPosition);
  box-shadow: 3px 3px 3px hsl(0 0% 0% / 19%);
}

.toolbar-popup-list {
  position: relative;
  z-index: 2;
  padding: 0;
  margin: 0;
  list-style: none;
}

.toolbar-popup-list-item {
  text-align: left;
}

.toolbar-popup-list-item-button {
  display: block;
  width: 100%;
  padding: 6px 12px;
  border: none;
  font-size: 15px;
  text-align: left;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
}

.toolbar-popup-list-item-button:focus {
  outline: none;
}

.toolbar-popup-list-item-button:active {
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
}
