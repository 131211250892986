.ptinav_standard {
  width: 100%;
}

.toolbar-menu-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 100%;
  overflow: hidden;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: opacity 0.3s;
}

.toolbar-button:focus,
.toolbar-menu-button:focus {
  outline-offset: 4px;
  outline: 2px solid;
}

.toolbar-button:active,
.toolbar-menu-button:active {
  outline: 0 !important;
}

.toolbar-menu-button[aria-expanded='true'] {
  cursor: pointer;
  pointer-events: none;
}

.toolbar-menu-button-current-page,
.toolbar-menu-button-total-pages {
  font-size: 16px;
}
