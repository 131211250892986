/* stylelint-disable declaration-no-important */
:root {
  --animationTime: 0.3s;
  --animationEasing: ease-in-out;
  --pageShadowStart: hsl(243 100% 7% / 0%);
  --pageShadowStop: hsl(0 0% 14% / 30%);
}

.page.mod-left-above,
.page.mod-right-above {
  z-index: 5;
}

.page-fold {
  position: absolute;
  z-index: 2;
  width: var(--cornerSize);
  height: var(--cornerSize);
  overflow: hidden;
  padding: 0;
  border: none;
  box-shadow: 0 0 0 transparent;
  background: #fff;
  cursor: pointer;
  transition: transform var(--animationTime) var(--animationEasing),
    box-shadow var(--animationTime) var(--animationEasing);
  outline: none;
}

.page-fold:active {
  outline: none;
}

.page-fold.mod-tl {
  top: 0;
  left: 0;
  transform: translate(calc(var(--cornerSize) * -1), calc(var(--cornerSize) * -1));
}

.page-fold.mod-bl {
  bottom: 0;
  left: 0;
  transform: translate(calc(var(--cornerSize) * -1), var(--cornerSize));
}

.page-fold.mod-tr {
  top: 0;
  right: 0;
  transform: translate(var(--cornerSize), calc(var(--cornerSize) * -1));
}

.page-fold.mod-br {
  right: 0;
  bottom: 0;
  transform: translate(var(--cornerSize), var(--cornerSize));
}

.page-fold:not(.mod-no-fold).mod-show {
  box-shadow: 0 0 10px hsl(0 0% 0% / 70%);
  transform: translate(0, 0);
}

.page {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: clip-path var(--animationTime) var(--animationEasing);
}

.page.mod-html {
  position: relative;
  background-color: #fff;
  transform-origin: 0 0;
}

.page.mod-html sup,
.page.mod-html sub {
  font-size: 50%;
  line-height: 50%;
}

/*In some sup sub, designer will add a span, if this rule is not set as below, the font-sizing will not be correctly set on text within the span*/
.page.mod-html sup *,
.page.mod-html sub * {
  font-size: 100%;
  line-height: 100%;
}

.page.mod-high-contrast {
  display: flex !important;
  width: 100%;
  max-width: 600px;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  line-height: 1.6;
  overflow-wrap: anywhere;
}

.page.mod-high-contrast.mod-side-arrow {
  padding: 20px;
}

/* If we don't shift this over by a small amount we get a thin slither
  of the background appear between the two pages.  */
.page.mod-html:nth-child(2) {
  left: -0.5px;
}

.page.mod-html * {
  box-sizing: border-box;
}

.page.mod-html.mod-blank {
  background-color: transparent;
}

.page.mod-above {
  display: block;
}

.page.mod-grey-bg {
  background-color: #eee;
}

.page-image {
  position: absolute;
}

/* This clip-path technique works by having two points in each of other corners that fold.
 If you only have one corner with two points and move them to the corner that's required,
 you'll most likely get an undesired transition animation.

 For example, take this right hand page, there are two points in each corner that
 needs to be clipped.

 *-----------* <- 2 points here
 |           |
 |           |
 |           |
 |           |
 *-----------* <- 2 points here

When it's time to show the clips we move one of the points up and the other point left
to give us the cut away angle. FireDox has a clip-path editor to make on the fly adjustments
nice and easy.

  Bottom Right Fold:

 *-----------*
 |           |
 |           |
 |           *
 |          /
 *------* /

 Top Right Fold:

 *---------*
 |          \
 |           \
 |           *
 |           |
 *-----------*

*/
/* DOUBLE PAGE - LEFT CLIPS */
.mod-left-page {
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 100%, 0 0, 0 0);
}

.mod-left-page[active-corner='tl'] {
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 100%, 0 var(--cornerSize), var(--cornerSize) 0);
}

.mod-left-page[active-corner='bl'] {
  clip-path: polygon(
    100% 0,
    100% 100%,
    var(--cornerSize) 100%,
    0 calc(100% - var(--cornerSize)),
    0 0,
    0 0
  );
}

/* DOUBLE PAGE - RIGHT CLIPS */
.mod-right-page {
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
}

.mod-right-page[active-corner='tr'] {
  clip-path: polygon(
    calc(100% - var(--cornerSize)) 0,
    100% var(--cornerSize),
    100% 100%,
    100% 100%,
    0 100%,
    0 0
  );
}

.mod-right-page[active-corner='br'] {
  clip-path: polygon(
    100% 0,
    100% 0,
    100% calc(100% - var(--cornerSize)),
    calc(100% - var(--cornerSize)) 100%,
    0 100%,
    0 0
  );
}

/* DOUBLE PAGE PREVENT CORNER FOLDS */
.mod-left-page.mod-no-fold,
.mod-right-page.mod-no-fold {
  clip-path: none !important;
}

/* SINGLE PAGE CLIPS */
.mod-single[active-corner='tl'] {
  clip-path: polygon(
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%,
    0 var(--cornerSize),
    var(--cornerSize) 0
  );
}

.mod-single[active-corner='bl'] {
  clip-path: polygon(
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    var(--cornerSize) 100%,
    0 calc(100% - var(--cornerSize)),
    0 0,
    0 0
  );
}

.mod-single[active-corner='tr'] {
  clip-path: polygon(
    calc(100% - var(--cornerSize)) 0,
    100% var(--cornerSize),
    100% 100%,
    100% 100%,
    0 100%,
    0 100%,
    0 0,
    0 0
  );
}

.mod-single[active-corner='br'] {
  clip-path: polygon(
    100% 0,
    100% 0,
    100% calc(100% - var(--cornerSize)),
    calc(100% - var(--cornerSize)) 100%,
    0 100%,
    0 100%,
    0 0,
    0 0
  );
}

.mod-single,
.mod-single[active-corner='tr'].mod-no-fold-right,
.mod-single[active-corner='br'].mod-no-fold-right,
.mod-single[active-corner='tl'].mod-no-fold-left,
.mod-single[active-corner='bl'].mod-no-fold-left {
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 100%, 0 0, 0 0);
}

.page-fold-div {
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.page-fold-div::after {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  content: '';
}

.page-fold-image.mod-tl,
.page-fold-image.mod-bl,
.page-fold-image.mod-tr,
.page-fold-image.mod-br {
  position: absolute;
  pointer-events: none;
  transform: scaleY(-1);
}

.page-fold-image.mod-tl {
  right: 0;
  bottom: 0;
}

.page-fold-image.mod-bl {
  top: 0;
  right: 0;
}

.page-fold-image.mod-tr {
  bottom: 0;
  left: 0;
}

.page-fold-image.mod-br {
  top: 0;
  left: 0;
}

/* Scale the page underneath by a tiny amount to prevent it from showing through */
.page.mod-shrink {
  /* stylelint-disable-next-line number-max-precision */
  transform: scale(0.997);
}

.mod-prev-behind {
  z-index: 2;
}

.mod-right-behind.mod-single {
  z-index: 1;
}

.mod-right-page.mod-single {
  z-index: 3;
}

.page.mod-right-behind::after,
.page.mod-prev-behind::after,
.page.mod-left-behind::after {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  content: '';
  background: transparent;
}

.page.mod-right-behind[active-corner='tr']::after {
  background: linear-gradient(
    225deg,
    var(--pageShadowStart) var(--cornerFadeStart),
    var(--pageShadowStop) var(--cornerFadeStop)
  );
}

.page.mod-right-behind[active-corner='br']::after {
  background: linear-gradient(
    315deg,
    var(--pageShadowStart) var(--cornerFadeStart),
    var(--pageShadowStop) var(--cornerFadeStop)
  );
}

.page.mod-left-behind[active-corner='tl']::after,
.page.mod-prev-behind[active-corner='tl']::after {
  background: linear-gradient(
    135deg,
    var(--pageShadowStart) var(--cornerFadeStart),
    var(--pageShadowStop) var(--cornerFadeStop)
  );
}

.page.mod-left-behind[active-corner='bl']::after,
.page.mod-prev-behind[active-corner='bl']::after {
  background: linear-gradient(
    45deg,
    var(--pageShadowStart) var(--cornerFadeStart),
    var(--pageShadowStop) var(--cornerFadeStop)
  );
}

.page-shadow {
  position: absolute;
  left: 50%;
  z-index: 10;
  height: 100%;
  opacity: 0.7;
  pointer-events: none;
  transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: translateX(-50%);
}

/* 1. SOFT CENTER (GOOD FOR LIGHT PAGES) */
.page-shadow.mod-light-center {
  width: 8%;
  background: linear-gradient(
    90deg,
    hsla(0 0% 0% / 0%) 0%,
    hsla(0 0% 0% / 1%) 25%,
    hsla(0 0% 0% / 25%) 40%,
    hsla(0 0% 0% / 35%) 50%,
    hsla(0 0% 0% / 25%) 60%,
    hsla(0 0% 0% / 1%) 75%,
    hsla(0 0% 0% / 0%) 100%
  );
}

/* 2. DARK CENTRE (GOOD FOR DARK PAGES) */
.page-shadow.mod-dark-center {
  width: 8%;
  background: linear-gradient(
    90deg,
    hsla(0 0% 0% / 0%) 0%,
    hsla(0 0% 0% / 20%) 22%,
    hsla(0 0% 0% / 30%) 40%,
    hsla(0 0% 0% / 45%) 46%,
    hsla(0 0% 0% / 55%) 48%,
    hsla(0 0% 0% / 65%) 50%,
    hsla(0 0% 0% / 55%) 52%,
    hsla(0 0% 0% / 45%) 54%,
    hsla(0 0% 0% / 30%) 60%,
    hsla(0 0% 0% / 20%) 78%,
    hsla(0 0% 0% / 0%) 100%
  );
}

/* 3. SOFT CENTRE, STRONG HIGHLIGHTS (GOOD FOR LIGHT PAGES)  */
.page-shadow.mod-light-center-highlight {
  width: 25%;
  background: linear-gradient(
    90deg,
    hsla(0 0% 100% / 0%) 0%,
    hsla(0 0% 100% / 25%) 14.5%,
    hsla(0 0% 100% / 25%) 16%,
    hsla(0 0% 100% / 25%) 17.5%,
    hsla(0 0% 0% / 10%) 30%,
    hsla(0 0% 0% / 25%) 46%,
    hsla(0 0% 0% / 35%) 48%,
    hsla(0 0% 0% / 50%) 50%,
    hsla(0 0% 0% / 35%) 52%,
    hsla(0 0% 0% / 25%) 54%,
    hsla(0 0% 0% / 10%) 70%,
    hsla(0 0% 100% / 25%) 83.5%,
    hsla(0 0% 100% / 25%) 84%,
    hsla(0 0% 100% / 25%) 85.5%,
    hsla(0 0% 100% / 0%) 100%
  );
}

/* 4. DARK CENTRE, SOFT HIGHLIGHTS (GOOD FOR DARK PAGES) */
.page-shadow.mod-dark-center-highlight {
  width: 25%;
  background: linear-gradient(
    90deg,
    hsla(0 0% 100% / 0%) 0%,
    hsla(0 0% 100% / 25%) 14.5%,
    hsla(0 0% 100% / 25%) 16%,
    hsla(0 0% 100% / 25%) 17.5%,
    hsla(0 0% 0% / 10%) 30%,
    hsla(0 0% 0% / 55%) 46%,
    hsla(0 0% 0% / 65%) 48%,
    hsla(0 0% 0% / 80%) 50%,
    hsla(0 0% 0% / 65%) 52%,
    hsla(0 0% 0% / 55%) 54%,
    hsla(0 0% 0% / 10%) 70%,
    hsla(0 0% 100% / 25%) 83.5%,
    hsla(0 0% 100% / 25%) 84%,
    hsla(0 0% 100% / 25%) 85.5%,
    hsla(0 0% 100% / 0%) 100%
  );
}

.page-shadow.mod-hide {
  width: 5%;
  opacity: 0;
}

.page-shadow.mod-hide[class*='highlight'] {
  width: 20%;
}
