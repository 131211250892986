/* stylelint-disable declaration-no-important, declaration-property-unit-blacklist  */
.tooltip {
  z-index: 199;
  padding: 6px 12px;
  border-radius: 5px;
  box-shadow: 0 2px 4px hsl(0 0 8 / 50%);
  background: #fff;
}

.tooltip[x-placement='top'] {
  top: -10px !important;
}

.tooltip[x-placement='bottom'] {
  top: 10px !important;
}

.tooltip-arrow {
  display: block;
  position: absolute;
  border: 4px solid #fff;
  margin-right: 0;
  margin-left: 0;
  transform: translateY(-50%) rotate(45deg);
}

[x-placement='top'] .tooltip-arrow {
  top: 100%;
}

[x-placement='bottom'] .tooltip-arrow {
  top: 0;
}

.tooltip-inner {
  font-size: 14px;
}
