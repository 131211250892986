.version-list {
  display: flex;
  margin-bottom: 20px;
}

.version-list-image-link {
  flex-shrink: 0;
  margin-right: 20px;
}

.version-list-heading-link::before {
  display: block;
  position: relative;
  content: '';
  margin-top: -6px;
}

.version-list-image {
  border: 1px solid #cbcbcb;
}

.version-list-heading-link {
  text-decoration: none;
}

.version-list-heading {
  margin: 0;
  color: #000;
}

.version-list-date {
  display: block;
  margin-top: 2px;
}
