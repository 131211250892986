.mobile-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  width: 100%;
  height: 49px;
  box-sizing: border-box;
  padding: 0 10px;
}

.mobile-menu-right {
  display: flex;
  align-items: center;
}

.mobile-menu-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: opacity 0.3s;
}

.mobile-menu-text {
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.mobile-menu-button:--focus-hover {
  opacity: 0.8;
}

.mobile-menu-button-svg {
  width: 100%;
  height: 100%;
}

.mobile-menu-button-svg.mod-logo {
  width: 38px;
}
