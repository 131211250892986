:root {
  --menu-item-padding: 15px;
}

@media (prefers-reduced-motion) {
  * {
    transition-duration: 0s !important;
  }
}

.nav-container {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  max-height: 48px;
}

.pt-nav {
  position: relative;
  display: flex;
  box-sizing: border-box;
  z-index: 500;
}

.pt-nav-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  width: auto;
  min-width: 100%;
}

.pt-nav-desktop {
  & .pt-nav-list.mod-level-0 {
    flex-direction: row;
  }

  & .pt-nav-list.mod-level-1,
  & .pt-nav-list.mod-level-2,
  & .pt-nav-list.mod-level-3 {
    position: absolute;
  }
}

.pt-nav-mobile {
  & .pt-nav-list.mod-level-0 {
    flex-direction: column;
  }

  & .pt-nav-item-link.mod-level-1,
  & .pt-nav-item-button.mod-level-1 {
    padding-left: 30px;
  }

  & .pt-nav-item-link.mod-level-2,
  & .pt-nav-item-button.mod-level-2 {
    padding-left: 45px;
  }

  & .pt-nav-sub-nav-container {
    flex-direction: column;
  }

  & .pt-nav-item-button,
  & .pt-nav-item-link {
    width: 100%;
  }

  & .pt-nav-item-button.mod-submenu.mod-level-1 .pt-nav-item-arrow,
  & .pt-nav-item-button.mod-submenu.mod-level-2 .pt-nav-item-arrow {
    transform-origin: center;
    transform: rotate(90deg);
  }
}

.pt-nav-list.mod-level-1 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  top: 100%;
}

.pt-nav-list.mod-level-2,
.pt-nav-list.mod-level-3 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  top: 0;
  left: 100%;
}

.pt-nav-sub-nav-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.pt-nav-item-button,
.pt-nav-item-link {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  padding: var(--menu-item-padding);
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-family: sans-serif;
  font-size: 14px;
  text-decoration: none;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pt-nav-item-button.mod-submenu {
  padding: var(--menu-item-padding) 30px var(--menu-item-padding) var(--menu-item-padding);
}

.pt-nav-item-arrow {
  border: 5px solid transparent;
  height: 0;
  margin-top: -3px;
  position: absolute;
  right: 1em;
  top: 50%;
  width: 0;
}

.pt-nav-item-arrow.mod-vertical {
  border-top-color: hsla(0, 0%, 100%, 0.5);
}

.pt-nav-item-arrow.mod-horizontal {
  border-left-color: hsla(0, 0%, 100%, 0.5);
}

.pt-nav-item-arrow.mod-vertical.mod-highlight {
  border-top-color: #fff;
}

.pt-nav-item-arrow.mod-horizontal.mod-highlight {
  border-left-color: #fff;
}

.pt-nav-list-item {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.pt-nav-list-item.mod-level-1 .pt-nav-item-link,
.pt-nav-list-item.mod-level-1 .pt-nav-item-button,
.pt-nav-list-item.mod-level-2 .pt-nav-item-link,
.pt-nav-list-item.mod-level-2 .pt-nav-item-button,
.pt-nav-list-item.mod-level-3 .pt-nav-item-link,
.pt-nav-list-item.mod-level-3 .pt-nav-item-button {
  width: 100%;
  text-align: left;
}

.pt-nav-list-item .pt-nav-item-link:focus-visible,
.pt-nav-list-item .pt-nav-item-button:focus-visible {
  outline-width: 3px;
  outline-style: solid;
  outline-offset: -3px;
}

.pt-nav-item-button[aria-expanded='false'] ~ .pt-nav-list {
  display: none;
}

.pt-nav-item-button[aria-expanded='true'] ~ .pt-nav-list {
  display: flex;
}

.pt-nav-right {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

/* Mobile Specific Styles */
.pt-nav-mobile-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49px;
}

.pt-nav-mobile-menu-button {
  display: flex;
  align-items: center;
  border: none;
  padding: 6px 8px;
  background-color: transparent;
  cursor: pointer;
  margin-left: 2px;
}

.pt-nav-mobile-menu-button:focus-visible {
  outline-width: 0;
  outline-style: solid;
}

.pt-nav-mobile-drawer {
  background: grey;
  width: min(350px, 100%);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.pt-nav-mobile-drawer-enter {
  transform: translateX(-100%);
}

.pt-nav-mobile-drawer-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}

.pt-nav-mobile-drawer-exit {
  transform: translateX(0);
}

.pt-nav-mobile-drawer-exit-active {
  transition: transform 300ms;
  transform: translateX(-100%);
}

.pt-nav-mobile-close-button {
  width: 18px;
  height: 18px;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

.pt-nav-mobile-close-container {
  display: flex;
  justify-content: flex-end;
  padding: 17px 14px 14px 0;
}

.pt-nav-mobile-menu-text {
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.pt-nav-mobile-menu-button:focus-visible,
.pt-nav-mobile-close-button:focus-visible {
  outline: 2px solid;
}

.pt-nav-mobile-close-button:focus-visible {
  outline-offset: 4px;
}

.pt-nav-mobile-menu-button-svg {
  width: 100%;
  height: 100%;
}

.pt-nav-mobile-menu-button-svg.mod-logo {
  width: 38px;
}

.mobile-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  width: 100%;
  height: 49px;
  box-sizing: border-box;
  padding: 0 10px;
}

.mobile-menu-right {
  display: flex;
  align-items: center;
}

.mobile-menu-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: opacity 0.3s;
}

/* OVERLAY
  ======== */
.pt-nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: hsl(0 0% 0% / 0%);
  pointer-events: none;
  transition: background 0.4s ease-in;
}

@media (prefers-reduced-motion) {
  .pt-nav-overlay {
    transition: none;
  }
}

.pt-nav-overlay.mod-active {
  z-index: 10;
  background: hsl(0 0% 0% / 40%);
  pointer-events: all;
}
