/* stylelint-disable */
:root {
  --buttonNavSize: 28px;
}

#ptimessage_wrap,
#ptibox_wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030;
  width: 100%;
  height: 100%;
}

#ptimessage_wrap {
  z-index: 1199;
}

#ptibox_wrap_inner,
#ptimessage_wrap_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

#ptibox_wrap * {
  box-sizing: border-box;
}

#ptibox_wrap.mod-hidden,
#ptimessage_wrap.mod-hidden {
  display: none;
  width: 0;
  height: 0;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#ptibox_outer {
  display: none;
  z-index: 1090;
  max-width: 100%;
  padding: 20px;
  background: transparent;
}

.lightbox-required-heading {
  margin-top: 0;
  font-size: 28px;
}

.lightbox-required-text {
  margin: 0;
  line-height: 1.4;
}

.lightbox-content {
  z-index: 1100;
  max-height: 83vh;
  overflow: auto;
  border-color: transparent;
  border-radius: 4px;
  margin: 0;
  color: #333;
  background-color: #fff;
  border-style: solid;
}

.lightbox-outer.mod-message .lightbox-content {
  border-radius: 0;
}

.pop-up-chart .lightbox-content {
  max-width: calc(100vw - 40px);
}

#ptibox_wrap:not(.pop-up-chart) .lightbox-content {
  transition: max-height 0.4s ease, width 0.4s ease;
}

.lightbox-content.mod-no-max-height {
  max-height: none;
}

.lightbox-content.mod-image {
  overflow: hidden;
  max-height: none;
  transition: none;
}

/* New Styles */
.lightbox-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.lightbox-loading {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1100;
  width: 40px;
  height: 40px;
  background: transparent;
  transform: translateX(-50%) translateY(-50%);
}

.ptibox_loading_spinner {
  width: 100%;
  height: 100%;
  background-image: url(../images/ptibox_progress.png);
  animation: spin 1.5s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* New CSS Based Spinner. Needs to work on more backgrounds so save it for the
  next release.

.lightbox-loading {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1100;
  width: 100px;
  height: 100px;
  background: transparent;
  transform: translateX(-50%) translateY(-50%);
}

.ptibox-loading-spinner {
  display: inline-block;
  position: relative;
  width: 90px;
  height: 90px;
}

.ptibox-loading-spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  margin: 8px;
  border: 8px solid rgb(92, 92, 92);
  border-radius: 50%;
  animation: ptibox-loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(92, 92, 92) transparent transparent transparent;
}
.ptibox-loading-spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.ptibox-loading-spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.ptibox-loading-spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes ptibox-loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
*/

.lightbox-overlay.mod-message {
  z-index: 1130;
}

.lightbox-outer {
  display: none;
  z-index: 1090;
  padding: 20px;
  background: transparent;
}

.lightbox-outer.mod-message {
  z-index: 1190;
}

.lightbox-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  z-index: 1095;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 12px #1b1b1b;
  transition: all 0.4s ease;
}

#ptimessage_inner {
  border-width: 10px;
  border-style: solid;
}

.pop-up-video .lightbox-content,
.pop-up-video .lightbox-inner {
  transition: none;
}

.lightbox-top-buttons {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  z-index: 1201;
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  border-radius: 50%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.66);
  background: #fff;
  cursor: pointer;
}

.lightbox-top-svg {
  width: 14px;
  height: 14px;
}

.lightbox-nav-buttons {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1201;
  opacity: 0.8;
  padding: 30px 5px;
  border: none;
  margin: 0;
  background: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  transition: opacity 0.3s;
}

.lightbox-nav-buttons:--focus-hover {
  opacity: 1;
  border: 2px solid black;
}

.lightbox-top-buttons:hover .lightbox-top-svg,
.lightbox-top-buttons:focus .lightbox-top-svg {
  opacity: 0.7;
}

.lightbox-top-buttons.mod-close {
  right: -10px;
}

.lightbox-top-buttons.mod-play {
  right: 28px;
}

.lightbox-top-buttons.mod-info {
  right: 67px;
}

.lightbox-nav-buttons.mod-next {
  transform: rotate(180deg);
}

.lightbox-nav-svg {
  width: var(--buttonNavSize);
  height: var(--buttonNavSize);
  opacity: 0.9;
}

.lightbox-button-inner {
  display: block;
  width: 100%;
  margin: 15px 0;
  text-align: center;
  background-color: transparent;
}

.lightbox-button-bg {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  margin: 0;
  text-decoration: none;
  background-color: #000;
  cursor: pointer;
}

.lightbox-button-bg-span {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.responsive-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
}

.responsive-container.responsive-video-popup > video:not(:fullscreen) {
  object-fit: cover;
}

.responsive-item {
  width: 100%;
  height: 100%;
}

#ptibox_div,
#ptimessage_div {
  position: relative;
  z-index: 1100;
  overflow: auto;
}

.lightbox-image {
  width: auto;
  height: 100%;
}

#ptibox_frame {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
}

#ptibox_left,
#ptibox_right {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 1111;
  width: 35%;
  height: 100%;
  cursor: pointer;
  visibility: visible;
  outline: none;
}

#ptibox_left {
  justify-content: flex-start;
  left: 0;
}

#ptibox_right {
  justify-content: flex-end;
  right: 0;
}

@media (max-width: 500px) {
  #ptibox_div_inner {
    background-image: none !important;
  }
}

#ptibox_div_inner img {
  max-width: 100%;
  height: auto;
}

#ptibox_div_inner table {
  width: 100%;
}

#ptibox_caption {
  display: none;
  z-index: 1200;
  width: 100%;
  opacity: 0.8;
  background-color: #000;
}

#ptibox_caption.mod-image {
  position: absolute;
}

#ptibox_caption.mod-chart {
  position: relative;
}

#ptibox_caption.mod-top {
  top: 0;
}

#ptibox_caption.mod-bottom {
  bottom: 0;
}

#ptibox_caption_inner {
  display: block;
  opacity: 1;
  padding: 12px;
  font-size: 14px;
  color: #fff;
}

#ptibox_caption_desc {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.7;
}

.ptibox_buttonBG {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  margin: 0;
  text-decoration: none;
  background-color: #000;
  cursor: pointer;
}

div#ptibox_div .ptipoll .pti_bar {
  display: block;
  width: 0;
  height: 26px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  margin-right: 10px;
  text-align: right;
  color: #fff;
  background: #000;
}

.pti_question {
  border: none;
  padding: 0;
  margin: 18px 0;
  color: #333;
}

.pticomp legend {
  max-width: 100%;
  margin-bottom: 10px !important;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.5;
}

.comp-email {
  font-size: 18px;
  margin-bottom: 16px;
}

.pticomp .pti_question .mod-bold {
  font-weight: bold;
}

.ptiforward .pti_question {
  padding: 20px 0 5px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.ptiforward .pti_answer input {
  width: 99%;
}

.ptiforward textarea {
  width: 100%;
  height: 100px;
  font-family: inherit;
}

.pti_result {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  color: #333;
}

.pti_result:not(.mod-hidden) {
  padding: 20px 0;
}

.email-verification {
  display: flex;
}

.email-verification-doc-image {
  flex-shrink: 0;
}

@media (max-width: 620px) {
  .email-verification {
    flex-direction: column;
  }
}

.ptiemailpass img {
  max-width: 140px;
  border: solid 1px #ccc;
  margin-right: 20px;
}

.ptiemailpass .pti_text p {
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  color: #333;
}

.ptiemailpass .pti_label {
  display: block;
  padding: 20px 0 5px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.ptiemailpass form {
  flex-grow: 1;
}

.ptiemailpass .pti_field input {
  width: 100%;
  padding: 5px;
  border: solid 1px #ccc;
  font-size: 16px;
}

.ptiemailpass .pti_privacy {
  padding: 20px 0 5px;
  font-size: 16px;
  color: #333;
}

.ptiemailpass .pti_privacy input {
  padding: 0;
  margin: 0 10px 0 0;
  line-height: 16px;
}

.ptiemailpass .pti_privacy label {
  padding: 0;
  margin: 0;
  line-height: 16px;
}

.ptiemailpass .pti_remember {
  padding: 5px 0;
  font-size: 16px;
  color: #333;
}

.ptiemailpass .pti_remember input {
  padding: 0;
  margin: 0 10px 0 0;
  line-height: 16px;
}

.ptiemailpass .pti_remember label {
  padding: 0;
  margin: 0;
  line-height: 16px;
}

.ptienterpagenumber {
  margin-top: 10px;
}

#ptienterpagenumbertext {
  width: 90px;
}

.ptienterpagenumber #ptienterpagenumberdesc {
  font-size: 14px;
  font-style: italic;
}

fieldset {
  padding: 0;
  border: none;
  margin: 0;
}

#ptishareinstructions {
  font-size: 14px;
  line-height: 1.5;
}

.container-inner {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.container-inner.mod-html {
  display: grid;
  width: 100%;
  height: 100%;
}

.mod-left-page {
  overflow: hidden;
  /* background-color: #ddd; */
  user-select: none;
}

.mod-right-page {
  overflow: hidden;
  /* background-color: #eee; */
  z-index: 2;
  user-select: none;
}

.mod-left-page-above {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.mod-right-page-above {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

/* Contents / Issues / Errors */
.ptiIssueError {
  max-width: 480px;
  padding: 50px 20px;
  border: solid 1px #ccc;
  margin: 10px auto;
  color: #666;
  background-color: #fff;
  text-align: left;
  line-height: 1.5;
  border-radius: 0.4rem;
}

/*** SUPERFISH ESSENTIAL STYLES ***/
.ptiMenuBar,
.ptiMenuBar * {
  padding: 0;
  margin: 0;
  list-style: none;
}

.ptiMenuBarBG {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  width: 100%;
  box-sizing: border-box;
  padding-right: 10px;
}

.desktop-menu-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ptiMenuBar {
  width: 100%;
  min-height: 49px;
  padding-top: 0;
  margin: 0;
  margin-bottom: 0;
}

.ptiMenuBar li {
  position: relative;
}

.ptiMenuBar ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99;
  min-width: 100%;
}

.ptiMenuBar ul li {
  min-width: 100%;
  list-style-type: none;
}

.ptiMenuBar li {
  position: relative;
  float: left;
}

.ptiMenuBar a {
  display: block;
  position: relative;
  height: 49px;
  line-height: 49px;
  text-decoration: none;
}

.ptiMenuBar a,
.ptiMenuBar a:visited {
  color: #fff;
}

/* THIS CSS IS IN PLACE TO STOP CUSTOM MENU STYLES FROM BREAKING AND
   POSITIONING THE DROPDOWNS TOO LOW */
.ptiMenuBar .ptiMenuBarLiLvl2 {
  top: 0 !important;
}

.ptiIssueError h1 {
  font-size: 20px;
}

#divPtiContainer_zoom.mod-placeholder {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(5px) brightness(0.75);
}

/* This timing value needs to remain in sync with SHOW_INTERACTIVITY_DELAY
within pageturn-ui. */
#divPtiContainer_zoom {
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

#divPtiContainer_zoom.mod-quick-hide {
  opacity: 0;
  transition-duration: 0s;
}

#divPtiContainer_zoom.mod-hide {
  opacity: 0;
  transition-duration: 0s;
}

#divPtiContainer_zoom.mod-zoom-in {
  cursor: zoom-in;
}

#divPtiContainer_zoom.mod-zoom-out {
  cursor: zoom-out;
}

/* IE needs its own special custom cursor as zoom-in and zoom-out are not supported */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #divPtiContainer_zoom.mod-zoom-in {
    cursor: url(../images/zoom-in.cur), auto;
  }

  #divPtiContainer_zoom.mod-zoom-out {
    cursor: url(../images/zoom-out.cur), auto;
  }
}

video {
  cursor: auto;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  outline: none;
}

/*** DEMO SKIN ***/
.ptiMenuBar a span {
  display: inline-block;
  white-space: nowrap;
  pointer-events: none;
}

.ptiMenuBarLiLvl1 {
  border-right: solid 1px #fff;
  border-left: none;
  margin: 0;
  text-align: center;
}

.ptiMenuBarLiLvl1:nth-child(1) {
  z-index: 50;
}

.ptiMenuBarLiLvl1:nth-child(2) {
  z-index: 49;
}

.ptiMenuBarLiLvl1:nth-child(3) {
  z-index: 48;
}

.ptiMenuBarLiLvl1:nth-child(4) {
  z-index: 47;
}

.ptiMenuBarLiLvl1:nth-child(5) {
  z-index: 46;
}

.ptiMenuBarLiLvl1:nth-child(6) {
  z-index: 45;
}

.ptiMenuBarLiLvl1:nth-child(7) {
  z-index: 44;
}

.ptiMenuBarLiLvl1:nth-child(8) {
  z-index: 43;
}

.ptiMenuBarLiLvl1:nth-child(9) {
  z-index: 42;
}

.ptiMenuBarLiLvl1:nth-child(10) {
  z-index: 41;
}

.ptiMenuBarLiLvl1 a {
  padding-right: 20px;
  padding-left: 20px;
  font-size: 14px;
}

.ptiMenuBar .ptiMenuBarUlLvl2 {
  margin-top: 0;
}

.ptiMenuBar .ptiMenuBarUlLvl3 {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.ptiMenuBar .ptiMenuBarLiLvl2,
.ptiMenuBar .ptiMenuBarLiLvl3 {
  position: relative;
  width: inherit;
  height: inherit;
  border-top: solid 1px #fff;
  border-right: none;
  border-left: none;
  margin-left: 0;
  text-align: left;
  background-color: #444;
}

.ptiMenuBar .ptiMenuBarLiLvl2 a,
.ptiMenuBar .ptiMenuBarLiLvl3 a {
  padding-left: 10px;
  font-size: 14px;
}

.ptiMenuBar li:hover,
.ptiMenuBar li.sfHover,
.ptiMenuBar a:focus,
.ptiMenuBar a:hover,
.ptiMenuBar a:active {
  outline: 0;
}

/*** arrows **/
.ptiMenuBar a.sf-with-ul {
  padding-right: 2.25em;
}

.sf-sub-indicator {
  display: block;
  position: absolute;
  top: 14px;
  right: 5px;
  width: 10px;
  height: 10px;
  overflow: hidden;
  text-indent: -999em;
}

a > .sf-sub-indicator {
  /* give all except IE6 the correct values */
  top: 14px;
  background-position: 0 -100px; /* use translucent arrow for modern browsers */
}

/* apply hovers to modern browsers */
a:focus > .sf-sub-indicator,
a:hover > .sf-sub-indicator,
a:active > .sf-sub-indicator,
li:hover > a > .sf-sub-indicator,
li.sfHover > a > .sf-sub-indicator {
  background-position: -10px -100px; /* arrow hovers for modern browsers */
}

/* point right for anchors in subs */
.ptiMenuBar ul .sf-sub-indicator {
  background-position: -10px 0;
}

.ptiMenuBar ul a > .sf-sub-indicator {
  background-position: 0 0;
}

/* apply hovers to modern browsers */
.ptiMenuBar ul a:focus > .sf-sub-indicator,
.ptiMenuBar ul a:hover > .sf-sub-indicator,
.ptiMenuBar ul a:active > .sf-sub-indicator,
.ptiMenuBar ul li:hover > a > .sf-sub-indicator,
.ptiMenuBar ul li.sfHover > a > .sf-sub-indicator {
  background-position: -10px 0; /* arrow hovers for modern browsers */
}

.ptiMenuBarMyAccount {
  display: block;
  position: absolute;
  top: 0;
  right: 110px;
  width: 38px;
  height: 38px;
  overflow: hidden;
  line-height: 38px;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.ptiMenuBarMyAccount:hover {
  background-position: 0 -38px;
}

.ptiMenuBarLogout {
  display: block;
  position: absolute;
  top: 0;
  right: 70px;
  width: 38px;
  height: 38px;
  overflow: hidden;
  line-height: 38px;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.ptiMenuBarLogout:hover {
  background-position: 0 -38px;
}

.ptiMenuBarLogo {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 38px;
  overflow: hidden;
  line-height: 38px;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.ptiMenuBarLogo:hover {
  background-position: 0 -38px;
}

/* SIDE NAV BUTTONS */

.side-nav {
  display: block;
  position: absolute;
  top: 50%;
  width: 49px;
  height: 100px;
  opacity: 1;
  padding: 0;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out, width 0.2s ease-in-out;
  transform: translateY(-50%);
}

.side-nav[aria-hidden='true'] {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

/* This matches constants.SMALL_SCREEN_WIDTH and .toolbar. So make sure you change it in those places */
@media (max-width: 900px) and (orientation: portrait) {
  .side-nav {
    display: none;
  }
}

.side-nav:hover .side-nav-svg {
  opacity: 0.6;
}

.side-nav:focus {
  outline: 2px solid;
}

.side-nav:active {
  outline: none;
}

.side-nav.mod-appear-onhover {
  width: 20px;
}

.side-nav.mod-appear-onhover:--focus-hover,
.side-nav.mod-appear-onhover.mod-wide {
  width: 49px !important;
}

.side-nav.mod-prev {
  left: 0;
}

.side-nav.mod-next {
  right: 0;
}

.side-nav-svg {
  width: 100%;
  height: 35px;
  transition: opacity 0.3s ease-in-out;
}

.side-nav-svg.mod-next {
  transform: rotate(180deg);
}

.skip-link-accessible {
  position: absolute;
  top: -1000px;
  left: -1000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 15px 10px;
  text-align: left;
  color: #000;
  text-decoration: none;
  background: #fff;
  outline: 2px solid black;
}

.skip-link-accessible:active,
.skip-link-accessible:focus {
  top: 0;
  left: 0;
  z-index: 1000;
  width: auto;
  height: auto;
  overflow: visible;
}

::cue {
  /* color: #eee;
  background-color: rgba(0, 0, 0, 0.7);
  outline: rgba(0, 0, 0, 0.7) 10px solid;
  line-height: 2rem; */
    color: #fff;
  background-color: rgb(0 0 0 / 60%);
}

/*** SUPERFISH ARROWS **/
.sf-arrows .sf-with-ul {
  padding-right: 2.5em;
}

/* styling for both css and generated arrows */
/* .slideout-nav-list-item-dropdown::after, */
.sf-arrows .sf-with-ul::after {
  position: absolute;
  top: 50%;
  right: 1em;
  width: 0;
  height: 0;
  content: '';
  border: 5px solid transparent;
  margin-top: -3px;

  /* order of following 3 rules important for fallbacks to work */
  border-top-color: rgba(255, 255, 255, 0.5);
}

.sf-arrows > li > .sf-with-ul:focus::after,
.sf-arrows > li:hover > .sf-with-ul::after,
.sf-arrows > .sfHover > .sf-with-ul::after {
  border-top-color: #fff; /* IE8 fallback colour */
}

/* styling for right-facing arrows */
.sf-arrows ul .sf-with-ul::after {
  border-color: transparent;
  margin-top: -5px;
  margin-right: -3px;
  border-left-color: #dfeeff; /* edit this to suit design (no rgba in IE8) */
  border-left-color: rgba(255, 255, 255, 0.5);
}

.sf-arrows ul li > .sf-with-ul:focus::after,
.sf-arrows ul li:hover > .sf-with-ul::after,
.sf-arrows ul .sfHover > .sf-with-ul::after {
  border-left-color: #fff;
}
