/* stylelint-disable declaration-property-unit-disallowed-list, custom-property-pattern, selector-class-pattern */
:root {
  --border-radius: 0.4rem;
  --questionFontSize: 18px;
  --answerFontSize: 14px;
}

/* stylelint-disable */
.comp-popup #ptibox_div,
.comp-popup #ptibox_div_inner {
  height: 100%;
}
/* stylelint-enable */
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.loading-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

/* stylelint-disable selector-max-type */
.loading-ring div {
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  box-sizing: border-box;
  border-radius: 50%;
  margin: 8px;
  animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loading-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.loading-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.loading-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
/* stylelint-enable selector-max-type */

@keyframes loading-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.competition-app {
  height: 100%;
}

.comp-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.comp-content-conatiner {
  flex: 1 0 auto;
}

.comp-title {
  margin: 0;
  font-size: 24px;
  text-align: center;
}

.question-item + .question-item {
  margin-top: 40px;
}

.question-image {
  display: block;
  max-width: 100%;
  max-height: 300px;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.error-container {
  line-height: 1.5;
}

.quesiton-item-question {
  margin: 0;
  font-size: var(--questionFontSize);
  line-height: 1.5;
  white-space: pre-line;
}

.question-item-feedback {
  margin: 0;
  font-size: 15px;
  line-height: 1.5;
}

.true-or-false-subtitle {
  font-size: 12px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.true-or-false-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  gap: 1rem;
}


.comp-expandable-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.comp-answer-text {
  font-size: var(--answerFontSize);
  font-weight: bold;
  overflow-wrap: anywhere;
  hyphens: auto;
}

.comp-answer-notes {
  font-size: var(--answerFontSize);
  line-height: 1.4;
  overflow-wrap: anywhere;
  hyphens: auto;
}

.comp-answer-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 50%;
  margin-right: 8px;
  background: #fff;
}

.neutral-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
}

.neutral-icon-icon {
  width: 100%;
  height: 2px;
  background: #000;
}

.blank-icon {
  width: 14px;
  height: 14px;
}

/* FORM ELEMENTS */
.question-item-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 0.5rem 0.7rem;
  border: 1px solid;
  border-color: #c2c2c2;
  border-radius: var(--border-radius);
  margin-bottom: 0.8rem;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.form-element-radio-span {
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 50%;
  font-size: var(--answerFontSize);
  line-height: 1.5;
  transition: 0.25s ease;
}

.form-element-radio-span::before {
  display: flex;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  content: '';
  border-radius: 50%;
  margin-right: 12px;
  background-color: transparent;
  transition: 0.25s ease;
}

.form-element-textarea {
  width: 100%;
  padding: 0.6rem;
  border-radius: var(--border-radius);
  font-family: inherit;
  font-size: 15px;
  line-height: 1.6;
  resize: vertical;
  transition: box-shadow 0.3s ease;
  filter: brightness(1.2);
  outline: none;
  border: 1px solid;
}

/* COMPETITION BUTTONS */
.comp-answer-list {
  padding: 0;
  margin: 0;
}

.comp-answer-list-answer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 10px;
  border: 1px solid;
  border-color: #c2c2c2;
  border-radius: var(--border-radius);
  margin-bottom: 12px;
  font-size: var(--answerFontSize);
  transition: box-shadow 0.3s ease;
  min-height: 2.44rem;
}

/* COMPETITION BUTTONS */
.comp-button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.comp-button {
  padding: 0.7rem 1.6rem;
  border: none;
  border-radius: var(--border-radius);
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s all ease;
}

.comp-button.mod-disabled {
  opacity: 0.6;
}

.comp-button:--focus-hover {
  outline: none;
  filter: brightness(1.2);
}

.comp-button.mod-secondary {
  background-color: transparent;
}

/* PROGRESS */
.progress-bar {
  height: 0.6rem;
  overflow: hidden;
  border-radius: var(--border-radius);
  font-size: 0.75rem;
  background-color: #e9ecef;
}

.progress-bar-status {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  transition: transform 0.3s ease;
  transition-property: transform;
  transform-origin: left center;
}

.progress-text {
  margin: 10px 0 0;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}

/* REVIEW */
.review-question + .review-question {
  margin-top: 36px;
}

.review-question-heading {
  margin: 0 0 12px;
  font-size: var(--questionFontSize);
  white-space: pre-line;
}

/* RESULTS AND FEEDBACK */

.comp-submit-retry-info {
  font-size: 14px;
  line-height: 1.5;
}

.placeholder + .placeholder {
  margin-top: 20px;
}


/* Image Questions and Answers */

.gallery-wrapper {
  display: block;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.gallery-item {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  overflow-wrap: anywhere;
  hyphens: auto;
}

/* .gallery-item input[type=radio] {
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  overflow: hidden;
  text-indent: -9999px;
  appearance: none;
  border: transparent;
} */
.gallery-item input[type=radio] {
  display: block;
  position: absolute;
  height: 1rem;
  width: 1rem;
  overflow: hidden;
  text-indent: -9999px;
  appearance: none;
  border: transparent;
  top: 155px;
  right: 1.28rem;
  z-index: -1;
}



.gallery-item input[type=radio]:focus {
  border: transparent;
  outline: none;
}

.gallery-item .gallery-item-detail {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  padding: 0.5rem;
  border: 1px solid;
  border-radius: 0.4rem;
  border-radius: var(--border-radius);
  transition: box-shadow 0.3s ease;
}

.gallery:not(.mod-pre-review) .gallery-item .gallery-item-detail {
  cursor: pointer;
}

.gallery-item img {
  position: relative;
  display: block;
  height: auto;
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  max-height: 100%;
}

.gallery-item .caption {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  padding: 0.5rem 0;
}

.comp-answer-list-image {
	min-width: 100px;
  width: 100px;
  max-width: 100px;
  margin-right: 1rem;
}

.comp-answer-list-image img {
	display: block;
	width: 100%;
	height: auto;
	aspect-ratio: 1 / 1;
	object-fit: contain;
	object-position: 50% 50%;
}	


.gallery-item-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.15);
  height: 180px;
  overflow: hidden;
  border-radius: calc(var(--border-radius) / 2);
}


.gallery-item-detail .gallery-item-image::before {
  position: absolute;
  bottom: calc(0.5rem + 5px);
  right: calc(0.5rem + 5px);
  display: flex;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  content: '';
  border-radius: 50%;
  background-color: transparent;
  transition: 0.25s ease;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.5);
}