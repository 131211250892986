.toolbar {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  width: 480px;
  min-height: 49px;
  box-sizing: border-box;
  padding: 0 10px;
  margin: 0 auto;
  background-color: #000;
}

.toolbar.mod-mobile {
  display: none;
}

/* This matches constants.SMALL_SCREEN_WIDTH and .side-nav.
  So make sure you change it in those places */
@media (max-width: 900px) and (orientation: portrait) {
  .toolbar.mod-mobile {
    display: flex;
  }

  .toolbar.mod-hidden-on-mobile {
    display: none;
  }
}

.toolbar-left {
  justify-content: flex-start;
}

.toolbar-left,
.toolbar-right {
  display: flex;
  align-items: center;
  width: 33%;
  height: 49px;
}

.toolbar-center {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
}

.toolbar-right {
  justify-content: flex-end;
}

.toolbar-button {
  width: 25px;
  height: 25px;
  opacity: 1;
  padding: 0;
  border: none;
  margin-top: 12px;
  margin-bottom: 12px;
  background: transparent;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

div.toolbar-button {
  cursor: default;
}

.toolbar-button.mod-width-auto {
  width: auto;
  margin: auto 10px;
}

.toolbar-button[aria-hidden='true'] {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.toolbar-button[data-action='ptiNextPage'] {
  transform: rotate(180deg);
}

.toolbar-button.mod-logo {
  width: 44px;
  margin-left: 10px;
}

.toolbar-customer-logo {
  position: relative;
  left: -10px;
  height: 38px;
}

.toolbar-customer-logo-link {
  display: block;
  width: 100%;
  height: 100%;
}

.toolbar-customer-logo-link:focus {
  outline-offset: 4px;
  outline: 2px solid;
}

.cookie-preference {
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 10px;
  border-radius: 50%;
}

.cookie-preference.mod-fixed {
  position: fixed;
}

/* This matches constants.SMALL_SCREEN_WIDTH and .side-nav.
  So make sure you change it in those places */
@media (max-width: 900px) and (orientation: portrait) {
  .cookie-preference {
    display: none;
  }
}

.cookie-preference .toolbar-popup {
  left: -2px;
}
