.keyboard-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1200;
}

.keyboard-overlay[aria-hidden='true'] {
  display: none;
  height: 0;
  width: 0;
}

.keyboard-overlay[aria-hidden='false'] {
  display: block;
  height: 100%;
  width: 100%;
}

.keyboard-container {
  position: absolute;
  width: 100%;
  padding: 15px 0;
  background-color: #ececec;
}

.keyboard-container[data-position="top"] {
  top: 0;
}

.keyboard-container[data-position="bottom"] {
  bottom: 0;
}

.virtual-keyboard-logo {
  position: absolute;
  width: 44px;
  bottom: 0;
  right: 10px;
  pointer-events: none;
}

@media (width < 1650px) {
  .virtual-keyboard-logo {
    display: none;
  }
}

.simple-keyboard[aria-hidden='true'] {
  display: none;
}

.simple-keyboard[aria-hidden='false'] {
  display: block;
  max-width: 1500px;
  margin: 0 auto;
}

.hg-button {
  box-shadow: none !important;
  border: 1px solid !important;
  height: 60px !important;
  border-radius: 0 !important;
}

@media (width > 900px) {
  .hg-button span {
    font-size: 22px !important;
  }
}
