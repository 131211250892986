:root {
  --cookieBorder: 1px solid #d9d9d9;

  --toggleInputOnColor: #3a851b;
  --toggleInputOffColor: #757575;
}

.cookie-notice {
  position: relative;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  box-shadow: 0 -2px 4px rgb(208 208 208 / 59%);
  text-align: center;
  color: #000;
  background: #fff;
  transition: 0.4s transform ease;
  transform: translateY(0);
}

.cookie-notice-header {
  margin: 0;
}

.cookie-notice-text {
  font-size: 17px;
  line-height: 1.55;
}

.cookie-notice-button-container {
  display: flex;
  justify-content: space-around;
}

.cookie-notice-button {
  background: #000;
  border-radius: 4px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  margin: 0;
  padding: 10px 26px;
}

.cookie-notice-button:focus,
.cookie-button:focus,
.focus-visible.cookie-detail-input:focus:checked + .cookie-detail-label,
.focus-visible.cookie-detail-input:focus + .cookie-detail-label {
  box-shadow: 0 0 0 6px #fff;
  outline: 3px solid #000;
  outline-offset: 3px;
}

.cookie-simple-form {
  display: flex;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
}

/** END COOKIE NOTICE */

.cookie-detail {
  margin-top: 30px;
}

.cookie-detail-section {
  padding: 18px 0;
}

.cookie-detail-input-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cookie-detail-input-text {
  margin-right: 14px;
  font-size: 14px;
  font-weight: bold;
}

.cookie-detail-input-text.mod-disabled {
  color: var(--toggleInputOffColor) !important;
}

.cookie-detail-input-text.mod-enabled {
  color: var(--toggleInputOnColor) !important;
}

.cookie-detail-section + .cookie-detail-section {
  border-top: var(--cookieBorder);
}

.cookie-detail-section-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.cookie-detail-section-heading {
  margin: 0 0 3px;
}

@media (width <= 540px) {
  .cookie-detail-section-head {
    flex-direction: column;
  }

  .cookie-detail-section-heading {
    margin: 0 0 10px;
  }
}

.cookie-detail-section-text {
  margin: 5px 0 0;
  font-size: 14.5px;
  line-height: 1.5;
}

.cookie-detail-buttons {
  display: flex;
  justify-content: flex-end;
}

@media (width <= 540px) {
  .cookie-detail-buttons {
    flex-direction: column;
  }
}

.cookie-button {
  transition: none;
  outline: none;
}

.cookie-button.mod-primary {
  background-color: #000;
}

@media (width > 540px) {
  .cookie-button + .cookie-button {
    margin-left: 15px;
  }
}

/* FANCY CHECKBOX */

.cookie-detail-label {
  display: block;
  position: relative;
  width: 50px;
  height: 25px;
  border-radius: 100px;
  text-indent: -70px;
  background: var(--toggleInputOffColor);
  cursor: pointer;
  transform: box-shadow;
  box-shadow: none;
}

.cookie-detail-label::after {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 19px;
  height: 19px;
  content: '';
  border-radius: 90px;
  background: #fff;
  transition: 0.3s;
}

.cookie-detail-input:checked + .cookie-detail-label {
  background: var(--toggleInputOnColor);
}

.cookie-detail-input:checked + .cookie-detail-label::after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}

.cookie-detail-input:active::after {
  width: 25px;
}

.cookie-detail-label span.cookie-detail-input-text.mod-enabled,
.cookie-detail-label span.cookie-detail-input-text.mod-disabled {
   display: block;
   transform: translateX( calc(-100% - 0.5rem));
   text-align: right;
   width: max-content;
}