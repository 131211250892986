.contents-wrapper {
  display: inline-flex;
  flex-wrap: wrap;
}

.contents-page-spread {
  display: flex;
  flex-wrap: wrap;
}

@media (width > 500px) {
  .contents-page-spread {
    margin: 15px;
  }
}

.contents-page-item {
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
}

.contents-page-link,
.contents-page-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
}

.contents-page-link:focus {
  position: relative;
  z-index: 5;
}

@media (width <= 500px) {
  .contents-page-item {
    margin-bottom: 20px;
  }

  .contents-item.mod-blank {
    height: 0 !important;
  }
}

.contents-item-link-text {
  margin: 10px 0;
  font-size: 14px;
}

.contents-item-link-text.mod-checkbox {
  margin-bottom: 5px;
}

.contents-page-item.mod-checkbox {
  flex-direction: column;
  align-items: center;
}

.contents-page-item.mod-checkbox.mod-focus {
  z-index: 5;
  border-radius: 4px;
}

.contents-page-select-all-checkbox,
.contents-page-checkbox {
  width: 16px;
  height: 16px;
}

.contents-page-checkbox {
  margin-bottom: 5px;
}

.contents-page-select-all {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 0.8rem;
  border: 1px solid #cecece;
  border-radius: 0.4rem;
  background: #fff;
}

.contents-page-select-all-checkbox {
  margin-left: 0.6rem;
}

.select-all-label {
  font-size: 14px;
}
