*,
::after,
::before {
  box-sizing: inherit;
}

::-moz-focus-inner {
  border: 0;
}

html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  color: #666;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

body.mod-zoom-enabled {
  -ms-content-zooming: none;
  touch-action: none;
  touch-action: none;
}

body.mod-slideout-menu-open {
  overflow: hidden;
}

body.mod-contain,
body.mod-slideout-menu-open,
body.mod-lightbox-open {
  overflow: hidden !important;
}

a img {
  border: none;
}

pinch-touch {
  display: block;
  touch-action: none;
}

pinch-touch > * {
  transform-origin: center;
}

.div-logo {
  padding: 0;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  margin: 0 auto;
  text-align: center;
}

.container-zoom {
  display: block;
  will-change: transform;
}

.container-zoom:focus {
  outline: none;
}

/* IE11 needs a height on the flex parent in order to push the bottom toolbar
 to the bottom of the browser window */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .container {
    height: 0;
  }
}

.container.mod-zoom-in {
  overflow: hidden;
}

.container-zoom {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  will-change: transform;
}

.mod-stackable .container-zoom {
  align-items: flex-start;
  margin: 5px 0;
}

.container-zoom.mod-flex {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.js-zoom {
  display: flex;
  flex: 1 0 auto;
}

.top-nav-items.mod-sticky,
.bottom-nav-items.mod-sticky {
  position: sticky;
  z-index: 9;
}

.top-nav-items.mod-sticky {
  top: 0;
}

.bottom-nav-items.mod-sticky {
  bottom: 0;
  z-index: 8;
}

.top-nav-items.mod-on-top,
.bottom-nav-items.mod-on-top {
  z-index: 100;
}
