.chart {
  position: relative;
}

.chart-canvas {
  width: 100%;
  height: 100%;
  cursor: auto;
}

.interactivity-item {
  display: block;
  padding: 0;
  border: none;
  cursor: pointer;
}

.contrast-view {
  & .interactivity-item {
    position: relative;
    width: 100%;
    opacity: 1;
    padding: 10px 15px;
    font-size: 16px;
    line-height: 1.7;
    color: #fff;
    background: #000;
    outline: none;

    &:hover,
    &:focus {
      outline-width: 6px;
      outline-style: solid;
      outline-color: #808080;
    }

    

    & * {
      pointer-events: none;
    }
  }

  & .panel-list-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.normal-view {
  & .interactivity-item {
    position: absolute;
    transition: opacity 0.3s;
    outline: none;
  }
}

.accessible-view {
  & .interactivity-item {
    position: absolute;
    outline-width: 4px;
    outline-style: solid;

    &:hover,
    &:focus {
    }
  }
}
