/* stylelint-disable */
.page.mod-html {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px 0;
}

.page.mod-high-contrast h1,
.page.mod-high-contrast h2,
.page.mod-high-contrast h3,
.page.mod-high-contrast p {
  margin: 0;
  color: #000;
}

.page.mod-high-contrast button p {
  color: #fff;
}
