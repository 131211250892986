.form-error {
  display: block;
  border-radius: 4px;
  color: #750000;
  background: #ffdcdc;
}

.form-error.mod-show {
  padding: 8px;
  margin-bottom: 10px;
}

.form-element + .form-element {
  margin-top: 20px;
}

.form-element-input {
  padding: 10px 8px;
  border: 1px solid;
  border-radius: 4px;
  font-size: 15px;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
}

.form-element-input.mod-full {
  width: 100%;
  max-width: 600px;
}

.form-element-input[aria-invalid='true'] {
  border-color: #e00000;
}

.form-element-input.mod-full-width {
  width: 100%;
}

.form-element-button {
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  transition: box-shadow 0.3s ease, filter 0.3s ease;
  outline: none;
}

.form-element-button.mod-full {
  width: 100%;
}

.form-element-button:--focus-hover {
  filter: brightness(1.2);
}

.form-element-button[aria-disabled='true'] {
  opacity: 0.5;
}

.form-element-label-text {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}

.form-element-label-helper-text {
  font-size: 13px;
  font-weight: normal;
}
