/* RESPONSIVE MENU */
.slideout-nav-close-button {
  position: absolute;
  top: 17px;
  right: 17px;
  width: 18px;
  height: 18px;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

.slideout-nav-close-button:--focus-hover {
  opacity: 0.5;
}

.slideout-nav-close-button-svg {
  width: 18px;
  height: 18px;
}

/* MENU */

.slideout-nav-button {
  border: none;
  background: transparent;
}

.slideout-nav-item {
  display: block;
  width: 100%;
  padding: 12px 0 12px 12px;
  margin: 0;
  /* stylelint-disable-next-line declaration-property-unit-blacklist */
  font-size: 16px;
  text-align: left;
  cursor: pointer;
}

/* RESPONSIVE SLIDE OUT MENU
========================== */
.slideout-nav {
  position: absolute;
  left: 0;
  z-index: 11;
  width: 370px;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  padding: 70px 0 0;
  box-shadow: 0 0 0 transparent;
  background: #fff;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transform: translateX(-100%);
}

@media (prefers-reduced-motion) {
  .slideout-nav {
    transition: none;
  }
}

@media (max-width: 400px) {
  .slideout-nav {
    width: calc(100vw - 60px);
  }
}

.slideout-nav[aria-hidden='false'] {
  box-shadow: 0 0 30px hsl(0 0% 0% / 65%);
  transform: translateX(0);
}

.slideout-nav-section + .slideout-nav-section {
  margin-top: 40px;
}

.slideout-nav-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.slideout-nav-item.mod-level-2 {
  padding-left: 30px;
}

.slideout-nav-item.mod-level-3 {
  padding-left: 50px;
}

.slideout-nav-list-item {
  margin: 0;
}

.slideout-nav-list-item.mod-submenu {
  position: relative;
  padding: 0;
}

.slideout-nav-list-item-menu {
  height: auto;
  overflow: hidden;
  padding: 0;
  list-style: none;
  transition: max-height 0.4s ease;
}

.slideout-nav-list-item-menu.mod-collapsed {
  max-height: 0;
}

.slideout-nav-list-item-dropdown,
.slideout-nav-list-item-link {
  text-decoration: none;
}

.slideout-nav-list-item-link {
  display: block;
  width: 100%;
}

.slideout-nav-list-item-dropdown {
  position: relative;
  width: 100%;
  border: none;
  /* stylelint-disable-next-line declaration-property-unit-blacklist */
  font-size: 16px;
  text-align: left;
  background: transparent;
  cursor: pointer;
}

/* OVERLAY
  ======== */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: hsl(0 0% 0% / 0%);
  pointer-events: none;
  transition: background 0.4s ease-in;
}

@media (prefers-reduced-motion) {
  .overlay {
    transition: none;
  }
}

.overlay.mod-open {
  z-index: 10;
  background: hsl(0 0% 0% / 40%);
  pointer-events: all;
}
