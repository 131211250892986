/* stylelint-disable */
:root {
  --tiger-1000: rgba(245, 109, 32, 1);
  --orange-light-1000: rgba(205, 75, 23, 1);
  --orange-light-800: rgba(205, 75, 23, 0.8);
  --orange-light-200: rgba(205, 75, 23, 0.2);
  --orange-light-50: rgba(205, 75, 23, 0.05);
  --orange-dark-1000: rgba(238, 106, 60, 1);
  --orange-dark-800: rgba(238, 106, 60, 0.8);
  --orange-dark-200: rgba(238, 106, 60, 0.2);
  --orange-dark-50: rgba(238, 106, 60, 0.05);
  --grey-solid-1000: rgba(0, 0, 0, 1);
  --grey-solid-900: rgba(26, 26, 26, 1);
  --grey-solid-850: rgba(38, 38, 38, 1);
  --grey-solid-800: rgba(51, 51, 51, 1);
  --grey-solid-50: rgba(247, 247, 247, 1);
  --grey-solid-0: rgba(255, 255, 255, 1);
  --grey-light-1000: rgba(0, 0, 0, 0.9);
  --grey-light-700: rgba(0, 0, 0, 0.6);
  --grey-light-500: rgba(0, 0, 0, 0.45);
  --grey-light-100: rgba(0, 0, 0, 0.1);
  --grey-light-50: rgba(0, 0, 0, 0.04);
  --grey-light-25: rgba(0, 0, 0, 0.02);
  --grey-dark-1000: rgba(255, 255, 255, 1);
  --grey-dark-700: rgba(255, 255, 255, 0.78);
  --grey-dark-500: rgba(255, 255, 255, 0.6);
  --grey-dark-100: rgba(255, 255, 255, 0.12);
  --grey-dark-50: rgba(255, 255, 255, 0.06);
  --grey-dark-25: rgba(255, 255, 255, 0.03);
  --blue-light-1000: rgba(31, 106, 190, 1);
  --blue-light-800: rgba(31, 106, 190, 0.8);
  --blue-light-200: rgba(31, 106, 190, 0.2);
  --blue-light-50: rgba(31, 106, 190, 0.05);
  --blue-dark-1000: rgba(90, 151, 228, 1);
  --blue-dark-800: rgba(90, 151, 228, 0.8);
  --blue-dark-200: rgba(90, 151, 228, 0.2);
  --blue-dark-50: rgba(90, 151, 228, 0.05);
  --green-light-1000: rgba(21, 115, 69, 1);
  --green-light-800: rgba(21, 115, 69, 0.8);
  --green-light-200: rgba(21, 115, 69, 0.2);
  --green-light-50: rgba(21, 115, 69, 0.05);
  --green-dark-1000: rgba(104, 161, 126, 1);
  --green-dark-800: rgba(104, 161, 126, 0.8);
  --green-dark-200: rgba(104, 161, 126, 0.2);
  --green-dark-50: rgba(104, 161, 126, 0.05);
  --red-light-1000: rgba(187, 41, 31, 1);
  --red-light-800: rgba(187, 41, 31, 0.8);
  --red-light-200: rgba(187, 41, 31, 0.2);
  --red-light-50: rgba(187, 41, 31, 0.05);
  --red-dark-1000: rgba(236, 128, 113, 1);
  --red-dark-800: rgba(236, 128, 113, 0.8);
  --red-dark-200: rgba(236, 128, 113, 0.2);
  --red-dark-50: rgba(236, 128, 113, 0.07);
  --amber-light-1200: rgba(121, 91, 15, 1);
  --amber-light-1000: rgba(215, 161, 12, 1);
  --amber-light-800: rgba(215, 161, 12, 0.8);
  --amber-light-200: rgba(215, 161, 12, 0.2);
  --amber-light-50: rgba(215, 161, 12, 0.05);
  --amber-dark-1000: rgba(235, 182, 61, 1);
  --amber-dark-800: rgba(235, 182, 61, 0.8);
  --amber-dark-200: rgba(235, 182, 61, 0.2);
  --amber-dark-50: rgba(235, 182, 61, 0.05);
  --yellow-1000: rgba(252, 193, 85, 1);
  --brand-dark-1000: var(--orange-dark-1000);
  --brand-dark-800: var(--orange-dark-800);
  --brand-dark-200: var(--orange-dark-200);
  --brand-dark-50: var(--orange-dark-50);
  --brand-light-1000: var(--orange-light-1000);
  --brand-light-800: var(--orange-light-800);
  --brand-light-200: var(--orange-light-200);
  --brand-light-50: var(--orange-light-50);
  --font-family-body: 'Inter Variable', Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans',
    Arial, sans-serif;
  --font-family-heading: var(--font-family-body);
  --font-size-2xs: 0.75rem;
  --font-size-xs: 0.875rem;
  --font-size-sm: 1rem;
  --font-size-md: 1.125rem;
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.4375rem;
  --font-size-2xl: 1.625rem;
  --font-size-3xl: 1.8125rem;
  --font-size-4xl: 2rem;
  --font-size-5xl: 2.25rem;
  --font-size-6xl: 2.5625rem;
  --font-line-height-2xs: 1.125rem;
  --font-line-height-xs: 1.375rem;
  --font-line-height-sm: 1.5rem;
  --font-line-height-md: 1.3125rem;
  --font-line-height-lg: 1.4375rem;
  --font-line-height-xl: 1.625rem;
  --font-line-height-2xl: 1.875rem;
  --font-line-height-3xl: 2.0625rem;
  --font-line-height-4xl: 2.3125rem;
  --font-line-height-5xl: 2.5625rem;
  --font-line-height-6xl: 2.9375rem;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --spacing-0: 0;
  --spacing-4: 0.25rem;
  --spacing-8: 0.5rem;
  --spacing-12: 0.75rem;
  --spacing-16: 1rem;
  --spacing-20: 1.25rem;
  --spacing-24: 1.5rem;
  --spacing-40: 2.5rem;
  --spacing-32: 2rem;
  --spacing-48: 3rem;
  --spacing-56: 3.5rem;
  --spacing-64: 4rem;
  --spacing-80: 5rem;
  --spacing-96: 6rem;
  --spacing-128: 8rem;
  --spacing-192: 12rem;
  --spacing-256: 16rem;
  --corner-radius-0: 0;
  --corner-radius-4: 0.25rem;
  --corner-radius-8: 0.5rem;
  --corner-radius-12: 0.75rem;
  --corner-radius-16: 1rem;
  --corner-radius-24: 1.5rem;
  --corner-radius-32: 2rem;
  --text-strong: var(--grey-light-1000);
  --text-weak: var(--grey-light-700);
  --text-brand: var(--brand-light-1000);
  --text-disabled: var(--grey-light-100);
  --text-error: var(--red-light-1000);
  --text-warning: var(--amber-light-1200);
  --text-success: var(--green-light-1000);
  --text-information: var(--blue-light-1000);
  --text-inverse-strong: var(--grey-dark-1000);
  --text-inverse-weak: var(--grey-dark-700);
  --text-inverse-disabled: var(--grey-dark-100);
  --fill-strong: var(--grey-light-1000);
  --fill-weak: var(--grey-light-50);
  --fill-weaker: var(--grey-light-25);
  --fill-hover: var(--grey-light-25);
  --fill-selected: var(--brand-light-1000);
  --fill-disabled: var(--grey-light-100);
  --fill-overlay: var(--grey-light-500);
  --fill-tiger: var(--tiger-1000);
  --fill-brand-strong: var(--brand-light-1000);
  --fill-brand-weak: var(--brand-light-50);
  --fill-error-strong: var(--red-light-1000);
  --fill-error-weak: var(--red-light-50);
  --fill-warning-strong: var(--amber-light-1200);
  --fill-warning-weak: var(--amber-light-50);
  --fill-success-strong: var(--green-light-1000);
  --fill-success-weak: var(--green-light-50);
  --fill-information-strong: var(--blue-light-1000);
  --fill-information-weak: var(--blue-light-50);
  --fill-inverse-strong: var(--grey-solid-0);
  --fill-inverse-weak: var(--grey-dark-50);
  --fill-inverse-press: var(--grey-dark-100);
  --fill-inverse-disabled: var(--grey-dark-100);
  --fill-white: var(--grey-solid-0);
  --fill-yellow: var(--yellow-1000);
  --stroke-strong: var(--grey-light-500);
  --stroke-weak: var(--grey-light-100);
  --stroke-selected: var(--brand-light-1000);
  --stroke-focus: var(--blue-light-1000);
  --stroke-disabled: var(--grey-light-100);
  --stroke-brand-strong: var(--brand-light-800);
  --stroke-brand-weak: var(--brand-light-200);
  --stroke-error-strong: var(--red-light-800);
  --stroke-error-weak: var(--red-light-200);
  --stroke-warning-strong: var(--amber-light-800);
  --stroke-warning-weak: var(--amber-light-200);
  --stroke-success-strong: var(--green-light-800);
  --stroke-success-weak: var(--green-light-200);
  --stroke-information-strong: var(--blue-light-800);
  --stroke-information-weak: var(--blue-light-200);
  --stroke-inverse-strong: var(--grey-dark-500);
  --stroke-inverse-weak: var(--grey-dark-100);
  --stroke-inverse-disabled: var(--grey-dark-100);
  --icon-neutral: var(--grey-light-500);
  --icon-brand: var(--brand-light-800);
  --icon-disabled: var(--grey-light-100);
  --icon-error: var(--red-light-800);
  --icon-warning: var(--amber-light-1200);
  --icon-success: var(--green-light-800);
  --icon-information: var(--blue-light-800);
  --icon-inverse: var(--grey-dark-500);
  --icon-inverse-strong: var(--grey-dark-1000);
  --icon-inverse-disabled: var(--grey-dark-100);
  --background-base: var(--grey-solid-0);
  --background-raised: var(--grey-solid-0);
  --background-overlay: var(--grey-solid-0);
  --background-sunken: var(--grey-solid-50);
  --background-alternate: var(--grey-solid-50);
  --background-brand: var(--brand-light-1000);
  --background-inverse: var(--grey-solid-900);
  --fill-press: var(--grey-light-50);
  --fill-inverse-hover: var(--grey-dark-50);
  --stroke-inverse-stronger: var(--grey-dark-700);
  --stroke-inverse-strongest: var(--grey-dark-1000);
  --stroke-strongest: var(--grey-light-1000);
  --stroke-stronger: var(--grey-light-700);
  --icon-strong: var(--grey-light-1000);
  --fill-black: var(--grey-solid-1000);

  --shadow-overlay: 0px 8px 8px -4px rgba(0, 0, 0, 0.04), 0px 20px 24px -4px rgba(0, 0, 0, 0.08);

  --transition-time-fast: 250ms;
  --transition-time-slow: 500ms;
}

@media (prefers-reduced-motion) {
  :root {
    --transition-time-fast: 0ms;
    --transition-time-slow: 0ms;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --text-strong: var(--grey-dark-1000);
    --text-weak: var(--grey-dark-700);
    --text-brand: var(--brand-dark-1000);
    --text-disabled: var(--grey-dark-100);
    --text-error: var(--red-dark-1000);
    --text-warning: var(--amber-dark-1000);
    --text-success: var(--green-dark-1000);
    --text-information: var(--blue-dark-1000);
    --text-inverse-strong: var(--grey-light-1000);
    --text-inverse-weak: var(--grey-light-700);
    --text-inverse-disabled: var(--grey-light-100);
    --fill-strong: var(--grey-dark-1000);
    --fill-weak: var(--grey-dark-50);
    --fill-weaker: var(--grey-dark-25);
    --fill-hover: var(--grey-dark-25);
    --fill-selected: var(--brand-dark-1000);
    --fill-disabled: var(--grey-dark-100);
    --fill-overlay: var(--grey-dark-500);
    --fill-brand-strong: var(--brand-dark-1000);
    --fill-brand-weak: var(--brand-dark-50);
    --fill-error-strong: var(--red-dark-1000);
    --fill-error-weak: var(--red-dark-50);
    --fill-warning-strong: var(--amber-dark-1000);
    --fill-warning-weak: var(--amber-dark-50);
    --fill-success-strong: var(--green-dark-1000);
    --fill-success-weak: var(--green-dark-50);
    --fill-information-strong: var(--blue-dark-1000);
    --fill-information-weak: var(--blue-dark-50);
    --fill-inverse-strong: var(--grey-solid-1000);
    --fill-inverse-weak: var(--grey-light-50);
    --fill-inverse-press: var(--grey-light-100);
    --fill-inverse-disabled: var(--grey-light-100);
    --fill-white: var(--grey-solid-0);
    --fill-yellow: var(--yellow-1000);
    --stroke-strong: var(--grey-dark-500);
    --stroke-weak: var(--grey-dark-100);
    --stroke-selected: var(--brand-dark-1000);
    --stroke-focus: var(--blue-dark-1000);
    --stroke-disabled: var(--grey-dark-100);
    --stroke-brand-strong: var(--brand-dark-800);
    --stroke-brand-weak: var(--brand-dark-200);
    --stroke-error-strong: var(--red-dark-800);
    --stroke-error-weak: var(--red-dark-200);
    --stroke-warning-strong: var(--amber-dark-800);
    --stroke-warning-weak: var(--amber-dark-200);
    --stroke-success-strong: var(--green-dark-800);
    --stroke-success-weak: var(--green-dark-200);
    --stroke-information-strong: var(--blue-dark-800);
    --stroke-information-weak: var(--blue-dark-200);
    --stroke-inverse-strong: var(--grey-light-500);
    --stroke-inverse-weak: var(--grey-light-100);
    --stroke-inverse-disabled: var(--grey-light-50);
    --icon-neutral: var(--grey-dark-500);
    --icon-brand: var(--brand-dark-800);
    --icon-disabled: var(--grey-dark-100);
    --icon-error: var(--red-dark-800);
    --icon-warning: var(--amber-dark-1000);
    --icon-success: var(--green-dark-800);
    --icon-information: var(--blue-dark-800);
    --icon-inverse: var(--grey-light-500);
    --icon-inverse-strong: var(--grey-light-1000);
    --icon-inverse-disabled: var(--grey-light-100);
    --background-base: var(--grey-solid-900);
    --background-raised: var(--grey-solid-850);
    --background-overlay: var(--grey-solid-800);
    --background-sunken: var(--grey-solid-1000);
    --background-alternate: var(--grey-solid-850);
    --background-brand: var(--brand-dark-1000);
    --background-inverse: var(--grey-solid-0);
    --fill-press: var(--grey-dark-50);
    --fill-inverse-hover: var(--grey-light-50);
    --stroke-inverse-stronger: var(--grey-light-700);
    --stroke-inverse-strongest: var(--grey-light-1000);
    --stroke-strongest: var(--grey-dark-1000);
    --stroke-stronger: var(--grey-dark-700);
    --icon-strong: var(--grey-dark-1000);
    --fill-black: var(--grey-solid-1000);
  }
}

.badge-count {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-xs);
  min-width: 20px;
  line-height: 1;
  height: var(--spacing-24);
  z-index: 1;
  background-color: var(--fill-error-strong);
  color: var(--text-inverse-strong);
  top: 0;
  right: 0;
  transform: scale(1) translate(40%, -40%);
  transform-origin: 100% 0%;
  padding: 0 var(--spacing-8);
  border-radius: var(--corner-radius-16);
  transition: transform var(--transition-time-fast) cubic-bezier(0.4, 0, 0.2, 1);
}

.toolbar-popup-list-item-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-16);
  white-space: nowrap;
}

.toolbar-popup-list-item-button .badge-count {
  transform-origin: 0 0;
  transform: translate(0);
  position: relative;
  background: var(--fill-strong);
  border: 1px solid var(--stroke-inverse-strong);
  color: var(--text-inverse-weak);
}

.notification {
  align-items: flex-start;
  background: var(--background-raised);
  border: 1px solid var(--stroke-weak);
  border-radius: var(--corner-radius-4);
  display: flex;
  gap: 10px;
  padding: var(--spacing-24);
  border-left: 4px solid var(--stroke-brand-strong);
  text-align: left;
  z-index: 1000;
  box-shadow: var(--shadow-overlay);
}

@supports (overflow: clip) {
  .notification {
    animation-duration: var(--transition-time-fast);
    animation-name: animate-fade;
    animation-delay: 0.25s;
    animation-fill-mode: backwards;
  }
}

.notification-neutral {
  background: var(--fill-inverse-strong);
  border-color: var(--stroke-weak);
  border-left-color: var(--stroke-brand-strong);
}

.notification > .icon {
  align-items: center;
  color: var(--text-strong);
  display: flex;
  height: var(--spacing-24);
  justify-content: center;
  width: var(--spacing-24);
}

.notification-neutral .icon {
  color: var(--icon-neutral);
}

.notification .main {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
}

.notification .title-wrapper {
  display: flex;
  gap: var(--spacing-16);
  align-items: top;
  justify-content: space-between;
  align-self: stretch;
}

.title-wrapper {
  display: flex;
  gap: var(--spacing-16);
  align-items: top;
  justify-content: space-between;
  align-self: stretch;
}

.notification .title {
  align-self: stretch;
  color: var(--text-strong);
  font-family: var(--font-family-heading);
  font-size: var(--font-size-md);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-line-height-md);
}

.notification .message {
  align-self: stretch;
  color: #0009;
  color: var(--text-weak);
  flex-grow: 1;
  font-family: var(--font-family-body);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--font-line-height-sm);
  margin: 0;
}

.title-wrapper .dismiss-notification {
  appearance: none;
  background: none;
  border: none;
  color: var(--icon-neutral);
  cursor: pointer;
  width: var(--spacing-24);
  height: var(--spacing-24);
  padding: 0;
}

#notifications {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 0.5rem;
  padding: 0.5rem;
  position: absolute;
  right: 0;
  bottom: 0;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: clip;
}

#notifications-mounting-point {
  display: block;
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 0;
  z-index: 1;
}

@keyframes animate-fade {
  0% {
    opacity: 0;
    transform: translateX(120%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
