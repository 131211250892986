.popup-heading {
  padding: 0;
  margin: 0 0 25px;
  font-weight: bold;
}

.popup-action-button {
  display: inline-block;
  width: 110px;
  height: 130px;
  border: none;
  background-color: transparent;
  background-position: top center;
  cursor: pointer;
  text-align: center;
}

.popup-action-button:--focus-hover {
  background-position: bottom center;
}

.popup-action-button.mod-download-all {
  /* background-image: url(../images/ptinav_tools_downloadall.png); */
}

.popup-action-button.mod-download-selected {
  /* background-image: url(../images/ptinav_tools_downloadselected.png); */
}

.popup-action-button.text-print-button,
.popup-action-button.text-download-button {
  display: block;
  width: auto;
  height: auto;
  font-size: 13px;
  text-decoration: none;
  color: #000000;
  border: 1px solid;
  margin: 0.5rem;
  padding: 0.5rem;
}

/* .popup-action-button.mod-print-all {
  background-image: url(../images/ptinav_tools_printall.png);
}

.popup-action-button.mod-print-selected {
  background-image: url(../images/ptinav_tools_printselected.png);
} */

.popup.mod-more-info {
  line-height: 1.5;
}

/* stylelint-disable */
.popup hr {
  border: 0;
  margin: 0 0 30px;
  border-top-width: 3px;
  border-top-style: solid;
  /* For IE11 - Otherwise it centers it */
  text-align: left;
}

.popup.mod-more-info a {
  text-decoration: none;
  cursor: pointer;
  transition: 0.5s all ease;
}

.popup.mod-more-info a:--focus-hover {
  opacity: 0.8;
  text-decoration: underline;
}

.ptiArticle a.Button {
  padding: 12px 24px;
  line-height: 50px;
  text-decoration: none;
  color: #fff;
  transition: 0.5s all ease;
}

.ptiArticle a.Button:--focus-hover {
  opacity: 0.8;
  cursor: pointer;
}

.ptiArticle div#ptibox_div_inner {
  background-position: top right;
  background-repeat: no-repeat;
}

.ptiArticle div#ptibox_content {
  background-position: bottom right;
  background-repeat: no-repeat;
}

.mod-more-info table {
  border: 2px solid transparent;
  margin: 20px auto 0;
  border-collapse: collapse;
}

.popup.mod-more-info td,
.popup.mod-more-info th {
  padding: 20px;
}

.popup.mod-more-info td {
  vertical-align: top;
}

/* POLLS / COMPS */
.ptipoll .pti_title,
.pticomp .pti_title {
  margin-top: 0;
}

.ptipoll .pti_answer {
  padding-bottom: 5px;
  font-size: 16px;
}

.pti_bar+.pti_answer {
  margin-top: 14px;
}

.pti-answer-label {
  display: flex;
  align-items: flex-start;
}

.popup-email-input {
  margin-top: 10px;
}

.session-warning-heading {
  margin: 0;
  font-size: 30px;
  line-height: 1.6;
  text-align: center;
}

.session-warning-time {
  font-variant-numeric: tabular-nums;
}

/* Prevent the cookie popup from recieving custom pop-up styles */
.cookie-modal div#ptibox_div_inner,
.cookie-modal div#ptibox_content {
  background: none;
}

.cookie-modal .lightbox-inner * {
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
}

.system-modal .lightbox-inner * {
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
}