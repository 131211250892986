:root {
  --gapSize: 15px;
}

body.mod-stackable {
  overflow-y: scroll;
}

.stackable-page-container {
  display: flex;
  flex-wrap: wrap;
}

.stackable-page-container-html {
  display: grid;
  grid-template-columns: 1fr;
}

.mod-gap.mod-single + .mod-gap.mod-single {
  margin-top: var(--gapSize);
}

.mod-gap.mod-double:nth-child(n + 3) {
  margin-top: var(--gapSize);
}

.js-page {
  position: relative;
  clip-path: none !important;
}

.js-page.js-unrendered-page + .js-page.js-unrendered-page {
  display: none !important;
}

.interactive-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-image-stack {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: transparent;
}

.page-image-stack:not(.initial) {
  transition: opacity 1s;
}

.page-image-stack.initial,
.page-image-stack.loaded,
.page-image-stack.error {
  opacity: 1;
}

.page-image-stack:not([src]) {
  visibility: hidden;
}

.stackable-no-access {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 10px 40px;
  background-color: #fff;
}

.stackable-no-access-heading {
  margin-bottom: 12px;
  font-size: 28px;
}

.stackable-no-access-text {
  max-width: 500px;
  padding: 0 50px;
  margin: 0;
  font-size: 20px;
  line-height: 1.5;
}

.stackable-no-access-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  margin: 20px 10px 10px;
  font-size: 18px;
  letter-spacing: 0.9px;
  color: #fff;
  background: #2988ff;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}

.stackable-no-access-button:--focus-hover {
  opacity: 0.8;
}

@media (width < 500px) {
  .lightbox-required-heading {
    font-size: 25px;
  }

  .lightbox-required-text {
    font-size: 16px;
  }

  .stackable-no-access-button {
    font-size: 16px;
  }
}

@media (width < 400px) {
  .lightbox-required-heading {
    font-size: 25px;
  }

  .lightbox-required-text {
    font-size: 16px;
  }

  .stackable-no-access-button {
    padding: 5px;
    font-size: 14px;
  }
}

@media (width < 600px) {
  .mod-double .stackable-no-access {
    padding: 10px;
  }

  .mod-double .stackable-no-access-heading {
    font-size: 20px !important;
  }

  .mod-double .lightbox-required-text,
  .mod-double .stackable-no-access-button {
    font-size: 13px !important;
  }
}
