.share-button {
  width: 50px;
  height: 50px;
  border: none;
  background: center / 33px no-repeat;
  cursor: pointer;
}

.share-button.mod-native {
  background-color: #fb4f2d;
  background-image: url(../images/share.svg);
}

.share-button.mod-facebook {
  background-color: #4267b2;
  background-image: url(../images/facebook.svg);
}

.share-button.mod-twitter {
  background-color: #000000;
  background-image: url(../images/x.svg);
}

.share-button.mod-linkedin {
  background-color: #21577d;
  background-image: url(../images/linkedin.svg);
}
