.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
}

.no-pointer {
  pointer-events: none;
}

.spacer.small {
  height: 10px;
}

/* This prevents elements from receiving an outline on click,
  will still appear on keyboard focus */
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
